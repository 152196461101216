<template>
    <div class="workReport">
        <div class="search">
            <div class="search-list">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan3 search-list-icon"></i>
                    <div class="search-list-text">
                        <span v-show="userStr.length == 0">{{ searchType == 1 ? '我部门的' : searchType == 2 ? '我汇报的' : '汇报给我的'}}</span>
                        <span v-show="userStr.length != 0">
                            <span v-for="item in userStr" :key="item.id">{{item.title}},</span>
                        </span>
                        <a-icon v-if="userStr.length != 0" @click="userStr = [];personObj.selectArr = [],searchType =1,_info()" class="tree-close" type="close-circle"/>
                        <a-select class="search-type" v-model="searchType" @change="_info">
                            <a-select-opt-group>
                                <span slot="label">类型</span>
                                <a-select-option value="1">我部门的</a-select-option>
                                <a-select-option value="2">我汇报的</a-select-option>
                                <a-select-option value="3">汇报给我的</a-select-option>
                            </a-select-opt-group>
                            <a-select-opt-group label="历史搜索">
                                <a-select-option :value="item.id" v-for="item in historyList" @click="userStr = [item]">{{ item.title }}</a-select-option>
                            </a-select-opt-group>
                            <div slot="dropdownRender" slot-scope="menu">
                                <v-nodes :vnodes="menu" />
                                <a-divider style="margin: 4px 0;" />
                                <div class="alone-btn" style="padding: 4px 8px; cursor: pointer;text-align: center;position: relative">
                                    打开组织架构
                                    <person style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0" v-bind="personObj" @getData="participatePerson"></person>
                                </div>
                            </div>
                        </a-select>
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
            </div>
            <div class="search-list">
                <div class="search-list-info">
                    <a-icon type="history" class="search-list-icon"/>
                    <div class="search-list-text">
                        <span>{{ selectDate.length==0?'自定义时间':__moment__(selectDate[0]).format('YYYY-MM-DD')+' 至 '+__moment__(selectDate[1]).format('YYYY-MM-DD') }}</span>
                        <a-icon v-if="selectDate.length != 0" @click="selectDate = [];dateType = 1;_info()" class="tree-close" type="close-circle"/>
                        <a-range-picker format="YYYY-MM-DD" style="position: absolute;top: 0;left: 0;opacity: 0" v-model="selectDate" @change="_info"/>
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
            </div>
            <div>
                <a-button :type="dateType == 3 ? 'primary': ''" @click="_dateType(3)">前天</a-button>
                <a-button :type="dateType == 2 ? 'primary': ''" @click="_dateType(2)" style="margin: 0 8px">昨天</a-button>
                <a-button :type="dateType == 1 ? 'primary': ''" @click="_dateType(1)">今天</a-button>
            </div>
        </div>

        <div class="list" v-for="item in infoData" :key="item.Id">
            <div class="item">
                <a-popover placement="topLeft">
                    <template slot="content">
                        <div class="flex" style="padding-right: 20px;">
                            <img class="approve-icon" :src="item.Photo" alt="">
                            <div>
                                <div class="approve-name">{{item.CreateUserName}} <span>{{ item.Position }}</span></div>
                                <div class="approve-post">{{item.ParentName}}</div>
                            </div>
                        </div>
                    </template>
                    <div style="display: inline-block;position: relative;">
                        <img class="avatar" :src="item.Photo" alt="">
                    </div>
                </a-popover>

                <div class="content">
                    <div class="content-header">
                        <span class="name">{{ item.CreateUserName }}</span>
                        <span class="date">{{item.ReportDate}}  ({{item.Week}})</span>
                    </div>
                    <div class="content-txt" v-html="item.Content"></div>
                    <div class="content-img" v-if="item.Files">
                        <img v-for="photo in item.Files.split(',')" :key="photo" @click="maxImage = 'https://smart-resource.sikegroup.com/' + photo;showMax = true" :src="'https://smart-resource.sikegroup.com/' + photo + '?imageView2/1/w/80/h/80'" alt="">
                    </div>
                    <div class="user-list">
                        <a-tag color="blue" v-if="item.Type==0">日报</a-tag>
                        <a-tag color="green" v-if="item.Type==1">周报</a-tag>
                        <a-tag color="orange" v-if="item.Type==2">月报</a-tag>
                        <span>汇报给</span>
                        <div v-for="tab in item.User" :key="item.Id + '-' + tab.UserId">
                            <a-popover placement="topLeft">
                                <template slot="content">
                                    <div class="flex" style="padding-right: 20px;">
                                        <img class="approve-icon" :src="tab.Avatar" alt="">
                                        <div>
                                            <div class="approve-name">{{ tab.UserName }} <span>{{ tab.UserPosition }}</span></div>
                                            <div class="approve-post">{{ tab.ParentName }}</div>
                                        </div>
                                    </div>
                                </template>
                                <div style="display: inline-block;position: relative;">
                                    <img :src="tab.Avatar" alt="">
                                </div>
                            </a-popover>
                        </div>
                    </div>
<!--                    评论-->
                    <div class="comment-list">
                        <div class="item" v-for="tab in item.Record" :key="tab.Id">
                            <a-popover placement="topLeft">
                                <template slot="content">
                                    <div class="flex" style="padding-right: 20px;">
                                        <img class="approve-icon" :src="tab.Photo" alt="">
                                        <div>
                                            <div class="approve-name">{{ tab.CreateUserName }} <span>{{tab.UserPosition}}</span></div>
                                            <div class="approve-post">{{tab.ParentName}}</div>
                                        </div>
                                    </div>
                                </template>
                                <div style="display: inline-block;position: relative;">
                                    <span>{{tab.CreateUserName}}：</span>
                                </div>
                            </a-popover>
                            <div v-html="tab.Content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!infoData" style="margin: 20px 0;text-align: center;color: #aaa;font-size: 35px">暂无数据</div>
        <img class="max-img" v-show="showMax" :src="maxImage" alt="">
        <div class="shade" v-show="showMax" @click="showMax = false;maxImage = ''"></div>
    </div>
</template>

<script>
import person from "@/components/SelectPersonnel/SelectPersonnel";
export default {
    name: "workReport",
    components: {
        person,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data(){
        return {
            searchType: 1,
            selectDate: [],
            historyList: [],
            dateType: 1,
            personObj: {
                rank: 1, //职级 0为不选，1为选
                nums: 100, //选的数量
                department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: "", //常用联系人 不要常用联系人传空''
                selectArr: [], //要传入的数据
                placeholder: "搜索名称"
            },
            userStr: [],
            infoData: '',
            maxImage: '',
            showMax: false,
        }
    },
    created() {
        //v1/workreport/list&SaasUserId=4b32fa8f208fa98ea8f1f09ebb76907b&Date=2021-08-11&Type=1
        this._info();
        this.historyList = JSON.parse(window.localStorage.getItem('reportUserList')) || [];
    },
    methods: {
        _dateType(i){
            this.dateType = i;
            this.selectDate = [];
            this.$set(this.personObj,'selectArr',[])
            this._info();
        },
        _info(){
            this.$message.loading("搜索中...");
            let str = '';
            this.userStr.map(res=>{
                str += res.id + ','
            });
            str = str.substr(0,str.length-1);
            if (this.selectDate.length != 0) {
                this.dateType = -1;
            }
            this.$axios.get(8000117,{
                StartDate: this.selectDate.length != 0 ? this.__moment__(this.selectDate[0]).format('YYYY-MM-DD') : this._initDate(this.dateType),
                EndDate: this.selectDate.length != 0 ? this.__moment__(this.selectDate[1]).format('YYYY-MM-DD') : this._initDate(this.dateType),
                Type: this.userStr.length == 0 ? this.searchType : '',
                UserStr: str,
            },res=>{
                this.$message.destroy();
                this.infoData = res.data.data;
            });
        },
        _initDate(i){
            let date = new Date().getTime();
            let num = (i - 1) * 86400000;
            return this.__moment__(new Date(date - num)).format('YYYY-MM-DD');
        },
        participatePerson(val) {
            let data = JSON.parse(window.localStorage.getItem('reportUserList')) || [];
            let isNew = false;
            let list=[];
            val.map(res=>{
                data.map(res2=>{
                    if(res.id == res2.id){
                        isNew = true;
                    }
                });
                if(isNew){
                    isNew = false;
                }else{
                    list.push(res);
                }
            })
            this.userStr = val;
            this.historyList = data.concat(list);
            window.localStorage.setItem('reportUserList',JSON.stringify(data.concat(list)));
            this._info();
        },
    },
}
</script>

<style scoped lang="less">
@import "./workReport.less";
</style>
